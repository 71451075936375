import CryptoJS from "crypto-js";
const SECRET_KEY = "TUGOFOODORDERAPP";
//export * from "./cart";
export const priceFormat = (price) => {
  return price.toLocaleString("en-US", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
  });
};

export const maskEmail = function (email) {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += "*";
    }
    return gp2;
  });
};

const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  return encrypted;
};

const decryptData = (encrypted) => {
  if (encrypted !== undefined && encrypted !== null && encrypted !== "[]") {
    const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrypted);
  } else {
    return encrypted;
  }
};

export const setStorage = (key, value) => {
  // localStorage.setItem(key, value);
  localStorage.setItem(key, encryptData(value));
};

export const getStorage = (key) => {
  //return localStorage.getItem(key)
  return decryptData(localStorage.getItem(key));
};

export const str_replace = (product_name) => {
  if (product_name !== "") {
    product_name = product_name.replaceAll("amp;", "&");
    product_name = product_name.replaceAll("apos;'", "'' ");
    product_name = product_name.replaceAll("quot;'", "'' ");

    return product_name;
  } else {
    return product_name;
  }
};

export const getOrderNotes = (OrderNote) => {
  var note_test = OrderNote.replaceAll(" PIXHALLCSL", "");
  note_test = note_test.replaceAll("amp;", "&");
  note_test = note_test.replaceAll("apos;", "'' ");
  note_test = note_test.replaceAll("apos;'", "'' ");
  note_test = note_test.replaceAll("quot;'", "'' ");
  note_test = note_test.replaceAll("quot;", "'' ");
  note_test = note_test.replaceAll("'' '", "'' ");
  return note_test;
};

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  return `${day}/${month}/${year}`;
};

export const enablePopup = (item) => {
  if (
    (item.productUSP !== null && item.productUSP !== "") ||
    (item.productDiet !== null && item.productDiet !== "") ||
    (item.productIngredient !== null && item.productIngredient !== "") ||
    (item.productSpec !== null && item.productSpec !== "")
  ) {
    return true;
  } else {
    return false;
  }
};

export const highlightAllergens = (allergens, str) => {
  allergens.forEach((item) => {
    var strRegExp = new RegExp("\\b" + item.allergenTitle + "\\b", "ig");
    str = str.replace(strRegExp, "<strong>" + item.allergenTitle + "</strong>");
  });

  return <div dangerouslySetInnerHTML={{ __html: str }} />;
};

export const hightLightIngredients = (ingredients, str) => {
  ingredients.forEach((item) => {
    var strRegExp = new RegExp("\\b" + item.ingredientTitle + "\\b", "ig");
    str = str.replace(strRegExp, "<strong>" + item.ingredientTitle + "</strong>");
  });

  return <div dangerouslySetInnerHTML={{ __html: str }} />;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string
  .split(/(\s|-)/)  // Split by space or hyphen, keeping the delimiter
  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // Capitalize each word
  .join('');  // Join back the words including the delimiters
};